<template>
  <div class="home">
    <div class="d-flex flex-column align-center">
      <div class="mt-12">
        <v-img v-if="this.$store.state.theme === 'payagent'"
          width="300"
          src="../../assets/PayAgentBranding/undraw_error.svg"
          contain
        ></v-img>
        <v-img v-else
          width="300"
          src="../../assets/CalmonyPayBranding/undraw_error.svg"
          contain
        ></v-img>
      </div>
      
      <p class="mt-8 text-h5 text-sm-h4 font-weight-black primary--text text-center">Ooops, something went wrong.</p>

      <div class="text-center mt-5">
        <p class="text-body-1">Try clicking the URL provided to you again.</p>


        <p class="text-body-1 mt-10 mb-0">Contact us on <span class="primary--text">branch@branchemail.com</span></p>
        <p class="text-body-1 my-0">Or call us on <span class="primary--text">555-555-555-5</span></p>
        
        
        <!-- <p class="text-body-1">If it still doesn`t work you can pay directly by <span class="font-weight-black">bank transfer</span> to:</p> -->


        <!-- Conditionally render if payment details stored in $store -->
        <!-- <p class="text-body-1 my-0">Account name: <span class="secondary--text font-weight-black">XYZBankAccount</span></p>
        <p class="text-body-1 my-0">Account no.: <span class="secondary--text font-weight-black">555-555-555</span></p>
        <p class="text-body-1 my-0">Sort code.: <span class="secondary--text font-weight-black">999-999-999</span></p>

        <p class="text-body-1 mt-10 mb-0">Contact us on <span class="primary--text">branch@branchemail.com</span></p>
        <p class="text-body-1 my-0">Or call us on <span class="primary--text">555-555-555-5</span></p> -->

      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'error404Page',
  }
</script>
